@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100&display=swap");
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: "Roboto" !important;
  background-color: black;
  color: white;
  font-size: 9px;
}

code {
  font-family: "Roboto Mono", monospace;
}
