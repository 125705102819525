#playground {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: block;
  top: 0;
  left: 0;
}

.presentation {
  position: absolute;
  // pointer-events: none;
  width: 100%;
  bottom: 0;
  display: block;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  &__text {
    margin-bottom: calc(env(safe-area-inset-bottom) + 50px);
  }
  &__lead {
    font-size: 1.4rem;
  }
}
